import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/home3/s1.png';
import img2 from '../../assets/img/home3/s2.png';
import img3 from '../../assets/img/home3/s3.png';


const servicesThreeData = [
  {
    id: uuidv4(),
    img: img2,
    title: "Email Marketing",
    desc: "send targeted messages to your subscribers, increase engagement and conversions.",
  },

  {
    id: uuidv4(),
    img: img1,
    title: "Digital Marketing",
    desc: "Drive growth and reach your target audience with our data-driven digital marketing services.",
  },

  {
    id: uuidv4(),
    img: img3,
    title: "Creative Design",
    desc: "Mailofy has advanced enough where it now supports some powerful concepts that can help with the flexibility.",
  },
];

export default servicesThreeData;