import React from 'react'
import Header from '../Components/Header/Header'
import PageBanner from '../Components/PageBanner'
import bannerBg from '../assets/img/page-banner.webp';
import FooterTwo from '../Components/Footer/FooterTwo';
import NewsWrapper from '../Components/News';

const News = () => {
  return (
    <>
      <Header/>
      <PageBanner title='News' bannerBg={bannerBg} currentPage='News' />
      <NewsWrapper/>
      <FooterTwo/>
    </>
  )
}

export default News