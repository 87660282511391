import React from 'react';
import { Routes, Route} from 'react-router-dom';  
import ScrollTopBtn from './Components/ScrollTopBtn';
import AboutPage from './pages/AboutPage';
import ServicePage from './pages/ServicePage';
import News from './pages/News';
import NewsDetails from './pages/NewsDetails';
import TeamPage from './pages/TeamPage';
import FaqPage from './pages/FaqPage';
import PricingPage from './pages/PricingPage';
import ServiceDetails from './pages/ServiceDetails';
import Home from './pages/Home';
import ProjectPage from './pages/ProjectPage';
import ContactPage from './pages/ContactPage';


function App() {
  
    return (
      <>
        <ScrollTopBtn />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/newsDetails" element={<NewsDetails />} />
          <Route path="/servicesDetails" element={<ServiceDetails />} />
          <Route path="/news" element={<News />} />
          <Route path="/projects" element={<ProjectPage />} />
        </Routes>
      </>
    );
  
}

export default App;
